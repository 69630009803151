import api from "../middleware/api";

export const delay = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));

export const handleDownloadUtil = async () => {
  try {
    const response = await api.get("/api/Download/download-folder", {
      responseType: "blob",
    });

    const blob = new Blob([response.data], { type: "application/zip" });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "Generated Data.zip";
    document.body.appendChild(link);
    link.click();
    link.remove();

    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error("Download failed", error);
  }
};
