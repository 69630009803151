import { ReactComponent as LogoT } from "../assets/logo.svg";
import stylesLogo from "../assets/styles/logo.module.css";

interface LogoProps {
  className?: string[];
}

const Logo: React.FC<LogoProps> = ({ className = [] }) => {
  const classNames = className
    .map((className) => stylesLogo[className])
    .filter(Boolean);

  return (
    <div className={classNames.join(" ")}>
      <LogoT />
    </div>
  );
};

export default Logo;
