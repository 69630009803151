import React from "react";
import { Link } from "react-router-dom";
import styles from "../assets/styles/homeScreen.module.css";
import Logo from "../components/Logo";
import LogoutButton from "../components/LogoutButton";
import { handleDownloadUtil } from "../utils/helpers";

const HomeScreen: React.FC = () => {
  return (
    <>
      <LogoutButton />
      <div className={styles.container}>
        <Logo className={["default", "mB"]} />
        <div className={styles.buttonWrapper}>
          <Link to="/iot-license-manager" className={styles.a}>
            IoT License Manager
          </Link>
          <Link to="/generate-iot-licenses" className={styles.a}>
            Generate IoT Licenses
          </Link>
          <Link to="/" onClick={handleDownloadUtil} className={styles.a}>
            Download My Files
          </Link>
        </div>
      </div>
    </>
  );
};

export default HomeScreen;
