import { useNavigate } from "react-router-dom";
import styles from "../assets/styles/goBackButton.module.css";

interface GoBackButtonProps {
  className?: string[];
  message?: string;
}

const GoBackButton: React.FC<GoBackButtonProps> = ({ className = [] }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/");
  };

  const classNames = className
    .map((className) => styles[className])
    .filter(Boolean);

  return (
    <div className={classNames.join(" ")}>
      <button onClick={handleClick} className={styles.button}>
        Home
      </button>
    </div>
  );
};

export default GoBackButton;
