import React from "react";

import styles from "../assets/styles/loader.module.css";

interface LoaderProps {
  className?: string[];
}

const Loader: React.FC<LoaderProps> = ({ className = [] }) => {
  const classNames = className
    .map((className) => styles[className])
    .filter(Boolean);

  classNames.push(styles["wrapperDefault"]);

  return (
    <div className={classNames.join(" ")}>
      <span className={styles.loader}></span>
    </div>
  );
};

export default Loader;
