import React from "react";
import ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import "./assets/styles/index.css";
import reportWebVitals from "./reportWebVitals";

import App from "./App";
import AdminRoute from "./components/AdminRoute";
import LoginScreen from "./screens/LoginScreen";
import NotFoundScreen from "./screens/NotFoundScreen";
import HomeScreen from "./screens/HomeScreen";
import LicenseManagerScreen from "./screens/LicenseManagerScreen";
import EditLicenseScreen from "./screens/EditLicenseScreen";
import GenerateIoTScreen from "./screens/GenerateIoTScreen";

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/" element={<App />}>
        <Route path="" element={<AdminRoute />}>
          <Route index element={<HomeScreen />} />
          <Route
            path="iot-license-manager"
            element={<LicenseManagerScreen />}
          />
          <Route
            path="iot-license-manager/:id"
            element={<EditLicenseScreen />}
          />
          <Route path="generate-iot-licenses" element={<GenerateIoTScreen />} />
        </Route>
      </Route>
      <Route path="/login">
        <Route index element={<LoginScreen />} />
      </Route>
      <Route path="*" element={<NotFoundScreen />} />
    </>
  )
);

const rootElement = document.getElementById("root") as HTMLElement;

if (rootElement) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    //<React.StrictMode>
    <RouterProvider router={router} />
    //</React.StrictMode>
  );
}

reportWebVitals();
