import React from "react";
import styles from "../assets/styles/confirmationDialog.module.css";

interface ConfirmationDialogProps {
  isOpen: boolean;
  onConfirm: (a?: any) => any;
  onCancel: () => void;
  title?: string;
  message?: string;
}

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  isOpen,
  onConfirm,
  onCancel,
  title,
  message,
}) => {
  if (!isOpen) return null;

  return (
    <div className={styles.wrapper}>
      <h2>{title}</h2>
      <p>{message}</p>
      <button onClick={onCancel}>Cancel</button>
      <button onClick={onConfirm}>Confirm</button>
    </div>
  );
};

export default ConfirmationDialog;
