import React from "react";
import api from "../middleware/api";
import { Button } from "rsuite";
import "rsuite/Button/styles/index.css";

const DownloadFolderButton: React.FC = () => {
  const handleDownload = async () => {
    try {
      const response = await api.get("/api/Download/download-folder", {
        responseType: "blob",
      });

      const blob = new Blob([response.data], { type: "application/zip" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "Generated Data.zip";
      document.body.appendChild(link);
      link.click();
      link.remove();

      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Download failed", error);
    }
  };

  return <Button onClick={handleDownload}>Download Folder</Button>;
};

export default DownloadFolderButton;
