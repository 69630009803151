import React, { useEffect, useState } from "react";
import api from "../middleware/api";
import { Steps, ButtonGroup, Button } from "rsuite";
import "rsuite/Button/styles/index.css";
import "rsuite/Steps/styles/index.css";
import "rsuite/ButtonGroup/styles/index.css";
import styles from "../assets/styles/generateIotScreen.module.css";
import Logo from "../components/Logo";
import Loader from "../components/Loader";
import DownloadFolderButton from "../components/DownloadZipButton";
import { useNavigate } from "react-router-dom";
import GoBackButton from "../components/GoBackButton";

const GenerateIoTScreen: React.FC = () => {
  const [klient, setKlient] = useState("");
  const [baza, setBaza] = useState("");
  const [pagestart, setPagestart] = useState<number>(1);
  const [pageStop, setPageStop] = useState<number>(30);

  const [apn, setApn] = useState<string>("");
  const [mqttHost, setMqttHost] = useState<string>("");
  const [mqttPort, setMqttPort] = useState<number>(0);
  const [mqttUser, setMqttUser] = useState<string>("");
  const [mqttPassword, setMqttPassword] = useState<string>("");
  const [mqttClient, setMqttClient] = useState<string>("");
  const [ftpHost, setFtpHost] = useState<string>("");
  const [ftpUser, setFtpUser] = useState<string>("");
  const [ftpPassword, setFtpPassword] = useState<string>("");
  const [altFtpPort, setAltFtpPort] = useState<boolean>(false);

  const [daliMySQL, setDaliMySQL] = useState<number>(0);

  const [listToHaveNodeRED, setListToHaveNodeRED] = useState<string[]>([
    "v1",
    "c1",
    "p1",
    "energy",
  ]);
  const [beInSensor, setBeInSensor] = useState<string[]>([]);
  const [beInCard, setBeInCard] = useState<string[]>([]);

  const [problem, setProblem] = useState<string>("");
  const [okej, setOkej] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [spustaj, setSpustaj] = useState<boolean>(false);
  const navigate = useNavigate();

  const options = {
    listToHaveNodeRED: [
      "v1",
      "v2",
      "v3",
      "c1",
      "c2",
      "c3",
      "p1",
      "p2",
      "p3",
      "q1",
      "q2",
      "q3",
      "energy",
      "energy_export",
      "tan_phi",
      "temperature",
      "frequency",
    ],
    beInSensor: [
      "v1",
      "v2",
      "v3",
      "c1",
      "c2",
      "c3",
      "p1",
      "p2",
      "p3",
      "energy",
      "q1",
      "q2",
      "q3",
      "energy_export",
      "tan_phi",
      "temperature",
      "frequency",
      "minimums_L1",
      "minimums_L2",
      "minimums_L3",
      "minimums_LN",
      "maximums_L1",
      "maximums_L2",
      "maximums_L3",
      "maximums_LN",
      "maximum_APP1",
      "maximum_APP2",
      "maximum_APP3",
      "maximum_APT",
      "appdemand",
      "apppeak",
      "peakyear",
      "firmver",
    ],
    beInCard: [
      "v1",
      "v2",
      "v3",
      "c1",
      "c2",
      "c3",
      "p1",
      "p2",
      "p3",
      "q1",
      "q2",
      "q3",
      "energy",
      "energy_export",
      "frequency",
      "tan_phi",
      "temperature",
      "binsensor",
      "lightcontrol",
      "apppeak",
      "appdemand",
      "peakyear",
      "firmver",
    ],
  };

  const [features, setFeatures] = useState({
    v1: true,
    v2: false,
    v3: false,
    c1: true,
    c2: false,
    c3: false,
    p1: true,
    p2: false,
    p3: false,
    q1: true,
    q2: false,
    q3: false,
    temperature: false,
    frequency: false,
    power_factor: false,
    app_demand: false,
    app_peak: false,
    gps: false,
  });
  const [minimums, setMinimums] = useState({
    minimums_L1: false,
    minimums_L2: false,
    minimums_L3: false,
  });
  const [maximums, setMaximums] = useState({
    maximum_L1: false,
    maximum_L2: false,
    maximum_L3: false,
  });

  const handleCheckboxChange = (
    category: string,
    option: string,
    checked: boolean
  ) => {
    if (category === "listToHaveNodeRED") {
      setListToHaveNodeRED((prevState) => {
        const updatedList = checked
          ? [...prevState, option]
          : prevState.filter((item) => item !== option);
        return updatedList;
      });
    } else if (category === "beInSensor") {
      setBeInSensor((prevState) => {
        const updatedList = checked
          ? [...prevState, option]
          : prevState.filter((item) => item !== option);
        return updatedList;
      });
    } else if (category === "beInCard") {
      setBeInCard((prevState) => {
        const updatedList = checked
          ? [...prevState, option]
          : prevState.filter((item) => item !== option);
        return updatedList;
      });
    } else if (category === "features") {
      setFeatures((prevState) => ({
        ...prevState,
        [option]: checked,
      }));
    } else if (category === "minimums") {
      setMinimums((prevState) => ({
        ...prevState,
        [option]: checked,
      }));
    } else if (category === "maximums") {
      setMaximums((prevState) => ({
        ...prevState,
        [option]: checked,
      }));
    }
  };

  const handleSubmit = async () => {
    if (step === 3 && beInCard.length === 0) {
      setProblem("Please check at least one option in Step 4.");
      return;
    }
    // if (pagestart % 10 !== 0 && pagestart !== 1) {
    //   alert("Page start when divided by 10 must not have a remainder");
    //   return;
    // }
    let prati = pagestart;
    if (pagestart === 1) {
      prati = 10;
    } else {
      prati = pagestart + 10;
    }

    if (pagestart > pageStop) {
      alert("PageStart must be less than PageStop.");
      return;
    }

    const trueFeatureOptions = Object.keys(features).filter(
      (key) => features[key as keyof typeof features] === true
    );
    const trueMinimumsOptions = Object.keys(minimums).filter(
      (key) => minimums[key as keyof typeof minimums] === true
    );
    const trueMaximumsOptions = Object.keys(maximums).filter(
      (key) => maximums[key as keyof typeof maximums] === true
    );

    const updatedBeInCard = Array.from(
      new Set([
        ...beInCard,
        ...trueFeatureOptions.map((e) => e.toLowerCase()),
        ...trueMinimumsOptions.map((e) => e.toLowerCase()),
        ...trueMaximumsOptions.map((e) => e.toLowerCase()),
      ])
    );

    try {
      setProblem("");
      setIsLoading(true);

      let pamti = 0;

      let prerabotiAlt: number = 0;

      altFtpPort ? (prerabotiAlt = 1) : (prerabotiAlt = 0);

      // Ako bara pomalce od 9 uredi
      if (pageStop - pagestart < 10) {
        const response2 = await api.post("/api/Generate/execute", {
          Klient: klient,
          Baza: baza,
          PageStart: pagestart,
          PageStop: pageStop,
          ListToHaveNodeRED: listToHaveNodeRED,
          BeInSensor: options.beInSensor,
          BeInCard: updatedBeInCard,
          Optionz: 2,
          DaliMySQL: daliMySQL,
          Seriski: "12345",
          Apn: apn,
          MqttHost: mqttHost,
          MqttPort: mqttPort,
          MqttUser: mqttUser,
          MqttPassword: mqttPassword,
          MqttClient: mqttClient,
          FtpHost: ftpHost,
          FtpUser: ftpUser,
          FtpPassword: ftpPassword,
          AltFtpPort: prerabotiAlt,
          Features: features,
          Minimums: minimums,
          Maximums: maximums,
        });

        pamti = response2.data.length / 17;
      }
      // Ako bara povekje od 10 uredi
      else if (prati % 10 === 0) {
        // Ako ostatok e 0 za nodestart
        const response = await api.post("/api/Generate/execute", {
          Klient: klient,
          Baza: baza,
          PageStart: prati,
          PageStop: pageStop,
          ListToHaveNodeRED: listToHaveNodeRED,
          BeInSensor: options.beInSensor,
          BeInCard: updatedBeInCard,
          Optionz: 1,
          DaliMySQL: daliMySQL,
          Seriski: "12345",
          Apn: apn,
          MqttHost: mqttHost,
          MqttPort: mqttPort,
          MqttUser: mqttUser,
          MqttPassword: mqttPassword,
          MqttClient: mqttClient,
          FtpHost: ftpHost,
          FtpUser: ftpUser,
          FtpPassword: ftpPassword,
          AltFtpPort: prerabotiAlt,
          Features: features,
          Minimums: minimums,
          Maximums: maximums,
        });
        pamti = response.data.length / 17;
      } else if (pageStop - pagestart >= 10) {
        // ostatok vo nodestart i nodestop
        const next_multiple_of_10 = Math.ceil(pagestart / 10) * 10;
        // prati prvo od nodestart so ostatok do kraj
        const response = await api.post("/api/Generate/execute", {
          Klient: klient,
          Baza: baza,
          PageStart: next_multiple_of_10 + 10,
          PageStop: pageStop,
          ListToHaveNodeRED: listToHaveNodeRED,
          BeInSensor: options.beInSensor,
          BeInCard: updatedBeInCard,
          Optionz: 1,
          DaliMySQL: daliMySQL,
          Seriski: "12345",
          Apn: apn,
          MqttHost: mqttHost,
          MqttPort: mqttPort,
          MqttUser: mqttUser,
          MqttPassword: mqttPassword,
          MqttClient: mqttClient,
          FtpHost: ftpHost,
          FtpUser: ftpUser,
          FtpPassword: ftpPassword,
          AltFtpPort: prerabotiAlt,
          Features: features,
          Minimums: minimums,
          Maximums: maximums,
        });

        // prati go ostatokot pred nodestart prv do 10
        const response2 = await api.post("/api/Generate/execute", {
          Klient: klient,
          Baza: baza,
          PageStart: pagestart,
          PageStop: next_multiple_of_10,
          ListToHaveNodeRED: listToHaveNodeRED,
          BeInSensor: options.beInSensor,
          BeInCard: updatedBeInCard,
          Optionz: 2,
          DaliMySQL: daliMySQL,
          Seriski: "12345",
          Apn: apn,
          MqttHost: mqttHost,
          MqttPort: mqttPort,
          MqttUser: mqttUser,
          MqttPassword: mqttPassword,
          MqttClient: mqttClient,
          FtpHost: ftpHost,
          FtpUser: ftpUser,
          FtpPassword: ftpPassword,
          AltFtpPort: prerabotiAlt,
          Features: features,
          Minimums: minimums,
          Maximums: maximums,
        });

        pamti = response.data.length / 17 + response2.data.length / 17;
      }

      if (pagestart - pageStop === 0) {
        setOkej(`Successfully generated 1 license!`);
      } else {
        setOkej(`Successfully generated ${pamti} licenses!`);
      }
      setSpustaj(true);
    } catch (error) {
      console.error("Error submitting data:", error);
      alert("Error submitting data.");
    } finally {
      setIsLoading(false);
    }
  };

  const [step, setStep] = useState(0);
  const onChange = (nextStep: any) => {
    setProblem("");
    setStep(nextStep < 0 ? 0 : nextStep > 3 ? 3 : nextStep);
  };

  const onNext = () => {
    if (step === 0 && (klient.length <= 0 || baza.length === 0)) {
      setProblem("Please complete all required fields in Step 1.");
      return;
    } else if (step === 1 && listToHaveNodeRED.length === 0) {
      setProblem("Please check at least one option in step 2.");
      return;
    } else if (step === 2 && beInSensor.length === 0) {
      setProblem("Please check at least one option in step 3.");
      return;
    }

    setCompletedSteps((prev) => [
      ...prev.filter((item) => item !== step),
      step,
    ]);
    onChange(step + 1);
  };
  const onPrevious = () => {
    if (step === 0 && klient.length <= 0) {
      setCompletedSteps((pre) => pre.filter((e) => e !== step));
    } else if (step === 1 && listToHaveNodeRED.length === 0) {
      setCompletedSteps((pre) => pre.filter((e) => e !== step));
    } else if (step === 2 && beInSensor.length === 0) {
      setCompletedSteps((pre) => pre.filter((e) => e !== step));
    }
    onChange(step - 1);
  };

  const [completedSteps, setCompletedSteps] = useState<number[]>([]);
  const [problemSteps, setProblemSteps] = useState<number[]>([]);
  const handleCheckStep = (index: number) => {
    if (index === step) return;
    setProblem("");
    const currentStep = step;
    const nextStep = step + 1;
    if (
      currentStep === 0 &&
      klient.length > 0 &&
      baza.length > 0 &&
      index === nextStep
    ) {
      setStep(index);
      setCompletedSteps((prev) => [
        ...prev.filter((item) => item !== step),
        step,
      ]);
      return;
    }

    if (
      currentStep === 1 &&
      listToHaveNodeRED.length > 0 &&
      index === nextStep
    ) {
      setStep(index);
      setCompletedSteps((prev) => [
        ...prev.filter((item) => item !== step),
        step,
      ]);
      return;
    }

    if (currentStep === 2 && beInSensor.length > 0 && index === nextStep) {
      setStep(index);
      setCompletedSteps((prev) => [
        ...prev.filter((item) => item !== step),
        step,
      ]);
      return;
    }

    if (index < step && completedSteps.includes(index)) {
      setStep(index);
      return;
    }

    if (
      [0, 1].every((e) => completedSteps.includes(e)) &&
      problemSteps.length === 0
    ) {
      setStep(index);
      return;
    }

    if (
      [0, 1].every((e) => completedSteps.includes(e)) &&
      problemSteps[0] === 3
    ) {
      setStep(index);
      return;
    }
  };
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <Logo className={["gnt"]} />
        <h1>Generate Data</h1>
        <div className={styles.wrapperSteps}>
          <Steps current={step} id="mySteps">
            {["Client Info", "NodeRED", "Config"].map((title, index) => (
              <Steps.Item
                key={index}
                title={title}
                onClick={() => handleCheckStep(index)}
                className={styles.stpz}
              />
            ))}
          </Steps>
        </div>
        <div className={styles.stpzWrap}>
          {step === 0 && (
            <div className={styles.wrapper2}>
              <div className={styles.wrapper3}>
                <div>
                  <label>Client:</label>
                  <input
                    type="text"
                    value={klient}
                    onChange={(e) => {
                      setKlient(e.target.value);
                      e.target.value.length === 0
                        ? setProblemSteps((pr) => [...pr, step])
                        : setProblemSteps((pr) => pr.filter((e) => e !== step));
                    }}
                  />
                </div>
                <div>
                  <label className={styles.asd}>Database:</label>
                  <input
                    type="text"
                    value={baza}
                    onChange={(e) => {
                      setBaza(e.target.value);
                      e.target.value.length === 0
                        ? setProblemSteps((pr) => [...pr, step])
                        : setProblemSteps((pr) => pr.filter((e) => e !== step));
                    }}
                  />
                </div>
              </div>
              <div className={styles.wrapper4}>
                <div>
                  <label>Node Start:</label>
                  <input
                    type="number"
                    value={pagestart}
                    onChange={(e) => {
                      let inputValue = Number(e.target.value);

                      if (/^0+/.test(String(inputValue))) {
                        inputValue = Number(
                          String(inputValue).replace(/^0+/, "")
                        );
                      }

                      if (inputValue <= 0) return;
                      setPagestart(inputValue);
                      e.target.value = String(inputValue);
                      Number(e.target.value) >= pageStop &&
                        setPageStop((e) => inputValue + 1);
                    }}
                  />
                </div>
                <div>
                  <label>Node Stop:</label>
                  <input
                    type="number"
                    value={pageStop}
                    onChange={(e) => {
                      let inputValue = Number(e.target.value);
                      if (/^0+/.test(String(inputValue))) {
                        inputValue = Number(
                          String(inputValue).replace(/^0+/, "")
                        );
                      }
                      setPageStop(inputValue);
                      e.target.value = String(inputValue);
                      if (pagestart > inputValue) {
                        setPageStop(pagestart + 1);
                      } else {
                        setPageStop(inputValue);
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          )}

          {step === 1 && (
            <div className={styles.ndr}>
              <div>
                <label>
                  <input
                    type="radio"
                    name="daliMySQL"
                    value="1"
                    checked={daliMySQL === 1}
                    onChange={() => setDaliMySQL(1)}
                  />
                  MySQL
                </label>
                <label>
                  <input
                    type="radio"
                    name="daliMySQL"
                    value="0"
                    checked={daliMySQL === 0}
                    onChange={() => setDaliMySQL(0)}
                  />
                  InfluxDB
                </label>
              </div>
              {daliMySQL === 1 &&
                options.listToHaveNodeRED.map((option) => (
                  <div key={option}>
                    <input
                      type="checkbox"
                      id={`listToHaveNodeRED-${option}`}
                      checked={listToHaveNodeRED.includes(option)}
                      onChange={(e) => {
                        handleCheckboxChange(
                          "listToHaveNodeRED",
                          option,
                          e.target.checked
                        );

                        const updatedList = e.target.checked
                          ? [...listToHaveNodeRED, option]
                          : listToHaveNodeRED.filter((item) => item !== option);
                        if (updatedList.length === 0) {
                          setProblemSteps((pr) => {
                            if (!pr.includes(step)) {
                              return [...pr, step];
                            }
                            return pr;
                          });
                        } else {
                          setProblemSteps((pr) => pr.filter((e) => e !== step));
                        }
                      }}
                    />
                    <label htmlFor={`listToHaveNodeRED-${option}`}>
                      {option}
                    </label>
                  </div>
                ))}
            </div>
          )}

          {step === 2 && (
            <div className={styles.cfg}>
              <div id={styles.inputz}>
                <div>
                  <label>APN:</label>
                  <input
                    type="text"
                    value={apn}
                    onChange={(e) => {
                      setApn(e.target.value);
                    }}
                  />
                </div>
                <div>
                  <label>MQTT Host:</label>
                  <input
                    type="text"
                    value={mqttHost}
                    onChange={(e) => {
                      setMqttHost(e.target.value);
                    }}
                  />
                </div>
                <div>
                  <label>MQTT Port:</label>
                  <input
                    type="number"
                    value={mqttPort}
                    onChange={(e) => {
                      let inputValue = Number(e.target.value);
                      if (inputValue < 0) return;
                      setMqttPort(Number(e.target.value));
                    }}
                  />
                </div>
                <div>
                  <label>MQTT User:</label>
                  <input
                    type="text"
                    value={mqttUser}
                    onChange={(e) => {
                      setMqttUser(e.target.value);
                    }}
                  />
                </div>
                <div>
                  <label>MQTT Password:</label>
                  <input
                    type="text"
                    value={mqttPassword}
                    onChange={(e) => {
                      setMqttPassword(e.target.value);
                    }}
                  />
                </div>
                <div>
                  <label>FTP Host:</label>
                  <input
                    type="text"
                    value={ftpHost}
                    onChange={(e) => {
                      setFtpHost(e.target.value);
                    }}
                  />
                </div>
                <div>
                  <label>FTP User:</label>
                  <input
                    type="text"
                    value={ftpUser}
                    onChange={(e) => {
                      setFtpUser(e.target.value);
                    }}
                  />
                </div>
                <div>
                  <label>FTP Password:</label>
                  <input
                    type="text"
                    value={ftpPassword}
                    onChange={(e) => {
                      setFtpPassword(e.target.value);
                    }}
                  />
                </div>
                {/* <div>
                  <label>ALT FTP Port:</label>
                  <input
                    type="number"
                    value={altFtpPort}
                    onChange={(e) => {
                      let inputValue = Number(e.target.value);
                      if (inputValue < 0) return;
                      setAltFtpPort(Number(e.target.value));
                    }}
                  />
                </div> */}
              </div>
              <div id={styles.asddz}>
                {Object.keys(features).map((feature) => (
                  <label key={feature}>
                    <input
                      type="checkbox"
                      checked={features[feature as keyof typeof features]}
                      onChange={(e) =>
                        handleCheckboxChange(
                          "features",
                          feature,
                          e.target.checked
                        )
                      }
                    />
                    {feature}
                  </label>
                ))}
                <label key={"kek"}>
                  <input
                    type="checkbox"
                    checked={altFtpPort}
                    onChange={(e) =>
                      e.target.checked
                        ? setAltFtpPort(true)
                        : setAltFtpPort(false)
                    }
                  />
                  ALT FTP Port
                </label>
              </div>
              <div>
                <h4>Minimums:</h4>
                {Object.keys(minimums).map((minimum) => (
                  <label key={minimum}>
                    <input
                      type="checkbox"
                      checked={minimums[minimum as keyof typeof minimums]}
                      onChange={(e) =>
                        handleCheckboxChange(
                          "minimums",
                          minimum,
                          e.target.checked
                        )
                      }
                    />
                    {minimum}
                  </label>
                ))}
                <h4 id={styles.ye}>Maximums:</h4>
                {Object.keys(maximums).map((maximum) => (
                  <label key={maximum}>
                    <input
                      type="checkbox"
                      checked={maximums[maximum as keyof typeof maximums]}
                      onChange={(e) =>
                        handleCheckboxChange(
                          "maximums",
                          maximum,
                          e.target.checked
                        )
                      }
                    />
                    {maximum}
                  </label>
                ))}
              </div>
            </div>
          )}
        </div>
        {isLoading && <Loader className={["gnt"]} />}
        {problem && <div className={styles.ezs}>{problem}</div>}
        {okej && <div className={styles.ezs}>{okej}</div>}
        <div className={styles.sButtons}>
          <ButtonGroup>
            {step === 0 ? (
              <Button onClick={() => navigate("/")}>Back</Button>
            ) : (
              <Button onClick={onPrevious} disabled={step === 0 || isLoading}>
                Back
              </Button>
            )}

            {step !== 2 ? (
              <Button onClick={onNext} disabled={step === 3}>
                Next
              </Button>
            ) : !spustaj ? (
              <Button
                id={isLoading ? "lo124128" : ""}
                onClick={handleSubmit}
                disabled={isLoading}
              >
                Submit
              </Button>
            ) : (
              <DownloadFolderButton />
            )}
          </ButtonGroup>
        </div>
        <GoBackButton className={["gnr"]} />
      </div>
    </div>
  );
};

export default GenerateIoTScreen;
