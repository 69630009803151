import React from "react";
import { Link } from "react-router-dom";

import styles from "../assets/styles/notFoundScreen.module.css";
import Logo from "../components/Logo";

const NotFoundScreen: React.FC = () => {
  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <Logo className={["default", "notFound"]} />
        <div className={styles.message}>
          <h1 className={styles.title}>404</h1>
          <p className={styles.subtitle}>Oops! Page Not Found</p>
          <p className={styles.description}>
            The page you’re looking for doesn’t exist or has been moved.
          </p>
          <Link to="/" className={styles.homeLink}>
            Go back to Home
          </Link>
        </div>
      </div>
    </div>
  );
};

export default NotFoundScreen;
