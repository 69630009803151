import React from "react";
import { Outlet } from "react-router-dom";
import { AuthAPI } from "./api/UserAPI";

const App: React.FC = () => {
  const handleClick = async () => {
    try {
      await AuthAPI();
    } catch (error) {}
  };
  return (
    <div onClick={handleClick}>
      <Outlet />
    </div>
  );
};

export default App;
