import axios from "axios";
import api from "../middleware/api";

interface Credentials {
  username: string;
  password: string;
}

export const LoginAPI = async (credentials: Credentials) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/api/User/login`,
      {
        Name: credentials.username,
        Password: credentials.password,
      },
      { withCredentials: true }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const AuthAPI = async (): Promise<void> => {
  try {
    await axios.get(`${process.env.REACT_APP_BASE_URL}/api/User/auth`, {
      withCredentials: true,
    });
  } catch (error) {
    throw error;
  }
};

export const LogoutAPI = async (): Promise<void> => {
  try {
    await api.post(
      "/api/User/logout",
      {},
      {
        withCredentials: true,
      }
    );
  } catch (error) {
    throw error;
  }
};
