import axios from "axios";

// Middleware za api, povikaj go so samo api.REQUEST, se isprakja kolaceto JWT i tajniot kluc
const api = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`,
  headers: {
    FKey: process.env.REACT_APP_F_KEY,
  },
  withCredentials: true,
});

export default api;
